import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./ci.css";
import "./reset.css";
import "./scrollbar.css";
import "./style.css";
import { HubContextProvider } from "./HubContext";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const container = document.getElementById("root");
const root = createRoot(container!);

let body = (
  <HubContextProvider>
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <App />
  </HubContextProvider>
);

if (process.env.NODE_ENV != "production") {
  body = (
    <React.StrictMode>
      {body}
    </React.StrictMode>
  );
}

root.render(body);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
